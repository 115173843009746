import 'pagepiling.js';
import 'slick-carousel';

$(function() {
	setSliderWidth($('.main-catalog__list'));
	$(window).resize(function() {
		setSliderWidth($('.main-catalog__list'));
	});

	setHistorySliderWidth($('.history__list'));
	$(window).resize(function() {
		setHistorySliderWidth($('.history__list'));
	});

	setSliderWidth($('.history__photos'));
	$(window).resize(function() {
		setSliderWidth($('.history__photos'));
	});

	if (!GG.isMobile) {
		let colorScheme = [1, 0, 0, 0, 0, 0];

		$('#pagepiling').pagepiling({
			menu: '#pp-menu',
			navigation: {
				position: 'left',
				anchors: ['descript', 'catalog', 'develop', 'history', 'about', 'news']
			},
			onLeave: function(index, nextIndex, direction) {
				if (colorScheme[nextIndex - 1]) {
					$('#pp-menu').addClass('dark');
					$('body').removeClass('small-header');
				} else {
					$('#pp-menu').removeClass('dark');
					$('body').addClass('small-header');
				}
			}
		});
	}

	$('.about .photos__list').slick({
		rows: 0
	});

	$('.about .clients__list').slick({
		rows: 0,
		slidesToShow: 4,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 1,
					dots: true,
					arrows: false
				}
			}
		]
	});

	function setHistorySliderWidth($sliderWrapper) {
		let width = parseInt(
			$($sliderWrapper)
				.closest('.content')
				.css('width')
		);

		$sliderWrapper.css({ width: width + 'px' });
	}

	function setSliderWidth($sliderWrapper) {
		let width =
			$(window).width() > 900
				? parseInt(
						$($sliderWrapper)
							.closest('.content')
							.css('width')
				  ) + 60
				: parseInt(
						$($sliderWrapper)
							.closest('.content')
							.css('width')
				  ) + 20;

		$sliderWrapper.css({ width: width + 'px' });
	}

	$('.main-catalog__list').slick({
		slidesToShow: 4,
		rows: 0,
		dots: true,
		autoplay: true,
		autoplaySpeed: 4000,
		responsive: [
			{
				breakpoint: 1800,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}
		]
	});

	$('.main-catalog__entry').each(function() {
		let descHeight = $(this)
			.find('.entry__desc')
			.css('height');

		$(this)
			.find('.entry__desc')
			.css({ 'margin-bottom': '-' + descHeight });
	});

	$('.develop__slider').slick({
		fade: true,
		infinite: false,
		asNavFor: '.develop__nav',
		rows: 0,
		speed: 300,
		arrows: true,
		prevArrow: $('.develop__btn.prev'),
		nextArrow: $('.develop__btn.next')
	});

	$('.develop__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		if (Math.abs(currentSlide - nextSlide) === 1 && !GG.isMobile) {
			let direction =
				currentSlide - nextSlide === 1 || currentSlide - nextSlide === (slick.slideCount - 1) * -1
					? 'rev'
					: 'fwd';
			let nextStep = nextSlide + 1;
			let $video = $('.' + direction + '.video__entry_' + (direction === 'fwd' ? nextSlide : nextStep));

			$('.develop__slider').addClass('hidden');
			$('.develop__nav-container').addClass('no-points');

			$video.one('pause', function() {
				setTimeout(function() {
					$video.get(0).currentTime = 0;
				}, 400);
				$video.removeClass('play');
				$('.develop__slider').removeClass('hidden');
				$('.develop__nav-container').removeClass('no-points');
			});

			$video
				.addClass('play')
				.get(0)
				.play();
		}
	});

	$('.develop__slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
		$('.develop__slider .slick-active .entry__text').addClass('vis');
	});

	$('.develop__nav').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '.develop__slider',
		dots: false,
		focusOnSelect: true,
		rows: 0
	});

	if (!GG.isMobile) {
		$('.develop__slider .slider__entry img').each(function() {
			let imgLg = $(this).data('src');
			$(this).attr('src', imgLg);
		});

		let mainVideo = `<video loop="" muted="" autoplay=""> <source src="/assets/video/video.mp4" type="video/mp4"> </video>`;

		let videos = `<div class="delevop__video">
						<video muted class="fwd video__entry video__entry_1" data-slide-direction="fwd">
							<source src="/assets/video/1f.mp4" type="video/mp4">
						</video>
						<video muted class="rev video__entry video__entry_1" data-slide-direction="rev">
							<source src="/assets/video/1r.mp4" type="video/mp4">
						</video>
						<video muted class="fwd video__entry video__entry_2" data-slide-direction="fwd">
							<source src="/assets/video/2f.mp4" type="video/mp4">
						</video>
						<video muted class="rev video__entry video__entry_2" data-slide-direction="rev">
							<source src="/assets/video/2r.mp4" type="video/mp4">
						</video>
						<video muted class="fwd video__entry video__entry_3" data-slide-direction="fwd">
							<source src="/assets/video/3f.mp4" type="video/mp4">
						</video>
						<video muted class="rev video__entry video__entry_3" data-slide-direction="rev">
							<source src="/assets/video/3r.mp4" type="video/mp4">
						</video>
					</div>`;

		setTimeout(function() {
			$(mainVideo).appendTo('.descript');
			$(videos).appendTo('.develop');
		}, 1000);
	}
});

/*

		let current = parseInt($('.slick-current').attr("data-slick-index")) + 1;

		let decl = currentSlide - nextSlide;

		if(current > 0 && current < $('.slick-slide').length - 1) {
			if(decl == 1 || decl == (slick.slideCount-1)*(-1) ) {
				console.log('is prev direction');
				$('.develop__slider').addClass('hidden');
				$('.develop__entry_' + (current - 1)).addClass('play').end().find('.rev')[0].play();
				setTimeout(function() {
					$('.develop__entry_' + (current - 1)).removeClass('play').end().find('.rev')[0].pause();
					$('.develop__slider').removeClass('hidden');
				}, 3000);

				console.log(current, decl, $('.develop__entry_' + (current - 1)));
			} else {
				console.log('is next direction');
				$('.develop__slider').addClass('hidden');
				$('.develop__entry_' + current).addClass('play').end().find('.fwd')[0].play();
				setTimeout(function() {
					$('.develop__entry_' + current).removeClass('play').end().find('.fwd')[0].pause();
					$('.develop__slider').removeClass('hidden');
				}, 3000);

				console.log(current, decl);
			}
		}
*/
